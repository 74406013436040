import React, { useEffect, useState } from "react";
import { auth, database } from "../firebase";
import { ThreeBounce } from "better-react-spinkit";
export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [pending, setPending] = useState(true);
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        // Get the ID token
        const idToken = await user.getIdToken();

        setTimeout(() => {
          const gcUserRef = database.ref(`FFSRT/${user.uid}`);
          gcUserRef.get().then(async (snapshot) => {
            if (snapshot.exists()) {
              await gcUserRef.update({
                UserUid: user.uid,
              });
              setCurrentUser({
                ...snapshot.val(),
                userType: 0,
                UserUid: user.uid,
                firebaseUser: user,
              });
              setPending(false);
            } else {
              const scUserRef = database.ref(`BCT/${user.uid}`);
              scUserRef.get().then(async (snapshot1) => {
                if (snapshot1.exists()) {
                  await scUserRef.update({
                    UserUid: user.uid,
                  });
                  setCurrentUser({
                    ...snapshot1.val(),
                    userType: 1,
                    UserUid: user.uid,
                    firebaseUser: user,
                  });
                  setPending(false);
                } else {
                  setCurrentUser(null);
                  setPending(false);
                }
              });
            }
          });
        }, 500);
      } else {
        setCurrentUser(null);
        setPending(false);
      }
    });

    // Clean up the subscription
    return () => unsubscribe();
  }, []);

  if (pending) {
    return (
      <div className="flex justify-center items-center flex-col h-screen">
        <ThreeBounce size={24} color="#03a9f4" />
        Please wait...
      </div>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        currentUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
